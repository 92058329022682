<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Purchase Pin 🚀">
                    <div class="text-center" v-if="load">
                        <b-spinner variant="primary" label="Text Centered" style="width: 3rem; height: 3rem;" class="mr-1" />
                    </div>
                    <b-container v-else>
              <b-form    @submit.prevent="processbutton">
    
                        <b-row align-v="center" class="mt-3 mb-3">
    
    
                            <b-col v-for="airtime in userairtime" :key="airtime.id" cols="6" md="3" class="mb-1 ">
                                <b-img :style="addstyle(airtime.code)" @click="selectitem(airtime.code)" thumbnail size="70px" fluid :src="mainurl + airtime.image" /></b-col>
    
                        </b-row>


                        <b-spinner v-if="loadprice"/>
                  
    <div v-else-if="selectedairtime == 'waec'">
 
                        <b-form-group label="How Many Pin You Want To Purchase" label-for="value">
                           <b-form-spinbutton
      id="demo-sb"
      v-model="pinvalue"
      size="lg"
      min="1"
      max="10"
    />
                        </b-form-group>




            <b-form-group label="Amount" label-for="Pin Amount">
                            <b-form-input v-model="pinamount" id="phone" size="lg" placeholder="Pin Amount" readonly  />
                        </b-form-group>
    
    </div>


             <div v-else-if="selectedairtime == 'jamb'">
                        <b-form-group label="Service Type" label-for="plan" >
                            <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"  taggable push-tags :options="option" placeholder="Please Select Service Type" class="select-size-lg" >
     <template slot="option" slot-scope="option">
      
       JAMB {{ option.serviceType }} 
   </template>
    
    <template slot="selected-option" slot-scope="option">
     
      JAMB {{ option.serviceType }} 
   </template>
                               </v-select>
                        </b-form-group>
                       <b-form-group label="Amount"  v-if="selected != null">
                            <b-form-input v-model="jambamount" id="phone" size="lg" placeholder="Pin Amount" readonly  />
                        </b-form-group>
    



                        <b-form-group label="JAMB Confirmation Code" label-for="jamb">
                            <b-form-input v-model="jambcode" id="jamb" size="lg" placeholder="JAMB Confirmation Code"   />
                        </b-form-group>



                        <b-form-group label="Name"  v-if="(jambname != null) && (verified == true)">
                            <b-form-input v-model="jambname" id="phone" size="lg" placeholder="Name" readonly  />
                        </b-form-group>



                        <b-form-group label="Phone" v-if="(jambphone != null) && (verified == true)">
                            <b-form-input v-model="jambphone" id="phone" size="lg" placeholder="Phone" readonly  />
                        </b-form-group>
                   </div>
                       
    
    
    
    
                        <div class="demo-inline-spacing"  v-if="checkbutton">
                            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" block variant="primary" :disabled="load || process">
                              <b-spinner v-if="process"
          small
          type="grow"
        />  
            <span v-else> Continue</span> 
                            </b-button>
                        </div>
      </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BAvatar, BFormSpinbutton, BFormRadio, BForm, BSpinner, BCardText, BButton, BFormInput, BImg, BFormGroup, BContainer, BRow, BCol } from 'bootstrap-vue'

import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import useServices from "@/auth/jwt/userServices"
export default {
    components: {
        BCard,
        BImg,
        vSelect,
        BFormSpinbutton,
        BForm,
        BContainer,
        BSpinner,
        BFormRadio,
        ToastificationContent,
        BFormInput,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },
    computed: {
        checkbutton() {


            return (this.selectedairtime != null) && (this.loadprice == false);
        }
    },


    data() {
        return {
            load: false,
             loadprice: false,
            option: [],
            jambcode: null,
            verified: false,
            waecpinamount: 0,
             jambamount: null,
             jambname: null,
             jambphone: null,
            pinvalue : 1,
            selectedairtime: null,
            mainurl: jwtDefaultConfig.mainurl,
            selected: null,
            process: false,
            pin: null,
            pinamount: null,
        }
    },

    created() {
        this.getairtim();
    },

    watch: {
     waecpinamount: function(value) {
         
         this.pinamount =  value * this.pinvalue;
     },


    pinvalue: function(value) {
         
         this.pinamount =  this.waecpinamount * value;
     },


      selected: function(value) {
      this.jambamount = value.amount;
                this.jambname = null;
    this.jambphone =  null;
    this.verified = false;

          
      }
,
      jambcode: function(value) {
            this.jambname = null;
    this.jambphone =  null;
    this.verified = false;
      }

    },

    methods: {



        processbutton() {

if (this.selectedairtime == "jamb") {


    if (this.verified == false) {
        this.confirmname();
    }else {

   this.processtransaction();


    }
    
}else  if(this.selectedairtime == "waec"){

   this.processtransaction();


}






        },



     async confirmname() {

         if ((this.jambcode != null) && (this.selected != null)) {
                  this.process = true;
            await useServices.jambconfirmname({jambcode: this.jambcode,servicetype: this.selected.serviceType}).then((result)=> {
if (result.data.status == false) {
     this.$swal({ title: 'Error!',
                  text: "Something went wrong, please try again",
                  icon: 'error',
                  customClass: {
                      confirmButton: 'btn btn-primary',
                  },
                            buttonsStyling: false,
                        })

    
} else if(result.data.status == true) {


    this.jambname = result.data.data.details.lastName + " "+result.data.data.details.middleName+ " " +result.data.data.details.firstName;
    this.jambphone =  result.data.data.details.gsmNo;
    this.verified = true;
}
              
             console.log(result.data.data)
            });

            this.process = false;
         }
       


      },



     async getprice(id) {
 this.loadprice = true;
            await useServices. geteducationprice({service: id}).then((result)=> {

                if (id == "waec") {
                  
                    this.waecpinamount = result.data.data[0].amount;



                  
                } else if (id == "jamb") {
                    this.option  = result.data.data.details.pricingOptions;
                     console.log(this.option)
                }
             console.log(result.data.data)
            });

            this.loadprice = false;


      }
,



        addstyle(id) {
            return this.selectedairtime == id ? "border: 0.3rem solid green" : "papa"
        },

       async selectitem(id) {
          await this.getprice(id);
            this.selectedairtime = id;
        },


        async getairtim() {
            this.load = true;
            await useServices.geteducation().then((result)=> {
                this.userairtime = result.data.data
            });

            this.load = false;

        },




        async processtransaction() {

            var params = {};

         if(this.selectedairtime == "jamb") {
             params = {type: this.selectedairtime, jambcode: this.jambcode, jambtype: this.selected.serviceType};
         } else if(this.selectedairtime == "waec") {
              params = {type: this.selectedairtime, amountofpin: this.pinvalue};
         }
                    this.process = true;
            try {
                await useServices.processeducation(params).then((result) => {

  this.process = false;
                    if (result.data.data.status == true) {


                     console.log(result.data.data.hash)

                     this.$router.push({name: "transaction", params: { hash: result.data.data.hash}});

                        console.log(result.data.data)

                    } else {

                        this.$swal({
                            title: 'Error!',
                            text: result.data.data,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })



                    }



                });
            } catch (error) {
                  this.process = false;
                console.log(error.response.status)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }

            }

            this.process = false;



        }
    },





}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>